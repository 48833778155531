import React from 'react'
import {
  CorrectiveActionsPageViewModel,
  CorrectiveActionsPageViewModelProps,
  useCorrectiveActionsPageViewModel,
} from './CorrectiveActionsViewModel'

import PageContainer from '../../components/page/PageContainer'
import CorrectiveActionDataGrid from './CorrectiveActionsDataGrid'
import {
  normalizeCorrectiveActionRows,
  normalizeCorrectiveActionRowsOld,
} from '../../components/datagrid/gridData'
import CorrectiveActionFilter from './CorrectiveActionFilter'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { EdifyButton } from '../../components/buttons'
import { useNavigate } from 'react-router-dom'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import CorrectiveActionFilterOld from './CorrectiveActionFilterOld'
import CorrectiveActionDataGridOld from './CorrectiveActionsDataGridOld'

export const ROUTE_SUBMISSIONS = '/submissions'
export const ROUTE_CORRECTIVE_ACTIONS = '/correctiveActions'

export const CorrectiveActionsPage: React.FC = () => {
  return (
    <CorrectiveActionsPageViewModel>
      <_Page />
    </CorrectiveActionsPageViewModel>
  )
}

export const _Page: React.FC = () => {
  const vm: CorrectiveActionsPageViewModelProps =
    useCorrectiveActionsPageViewModel()
  const orgVm = useOrganizationProvider()
  const navigate = useNavigate()
  // need to use corrective active form permission
  const canEdit = usePermission(ERole.SubmissionEditAll)


  const renderRightItems = () => {
    if (vm.canCreate) {
      return (
        <EdifyButton
          title={'New Corrective Action'}
          onClick={()=> navigate(`${ROUTE_CORRECTIVE_ACTIONS}/new/${orgVm.org?.caFormId}`)}
        />
      )
    }
  }
  const getRowsOld = () => {
    return vm.correctiveActions
      ? normalizeCorrectiveActionRowsOld(vm.correctiveActions, orgVm.flags.isLocationEnabled, orgVm.flags.isSubLocationEnabled, )
      : []
  }

  const getRows = () => {
    return vm.correctiveActions
      ? normalizeCorrectiveActionRows(
        [...vm.correctiveActions],
        navigate,
        canEdit,
        vm,
      )
      : []
  }

  const renderCorrectiveActions = () => {
    // TEMPORARY
    if (orgVm.org?.name === 'Shea Homes') {
      return (
        <>
          <CorrectiveActionFilter vm={vm} />
          <CorrectiveActionDataGrid
            vm={vm}
            title={'Corrective Action'}
            rows={getRows()}
          />
        </>
      )
    }
    return (
      <>
        <CorrectiveActionFilterOld vm={vm} />
        <CorrectiveActionDataGridOld
          vm={vm}
          title={'Corrective Action'}
          rows={getRowsOld()}
        />
      </>
    )
  }
  return (
    <PageContainer
      // mainPageLoading={vm.isLoading || vm.initialLoad}
      title={'Corrective Actions'}
      noMaxWidth={true}
      breadCrumbs={[
        { title: 'All Corrective Actions', to: ROUTE_CORRECTIVE_ACTIONS },
      ]}
      renderRightItems={renderRightItems}
    >
      {renderCorrectiveActions()}
    </PageContainer>
  )
}
