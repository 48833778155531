import React, { useContext, useEffect, useState } from 'react'

import {
  ECorrectiveActionStatus,
  ICorrectiveAction,
  ICorrectiveActionComment,
} from '../../../domain/interfaces/ICorrectiveAction'
import { useParams } from 'react-router-dom'
import {
  getCorrectiveActionById,
  getCorrectiveActionsComment,
  getCurrentPerson,
  getCurrentUser,
  getForm,
} from '../../../domain/domain'
import { IUser } from '../../../domain/interfaces/IUser'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import {
  anonGetOneForm,
  anonGetOneSubmission,
  getContractorById,
  getSubmissionById,
} from '../../../data/data'
import { IContractor } from '../../../domain/interfaces/IContractor'
import { IFormComponent } from '../../../domain/interfaces/IFormComponent'
import { findComponent } from '../../../core/utils/formio-formatters/FormIOFormatter'
import { getContactById } from '../../../data/repositories/contacts/ContactsRepository'

export const SHEA_HOMES_ID = 'f8782756-9dd8-45cf-9495-70131108fd7f'

export enum ECorrectiveActionViewMode {
  CONTRACTOR = 'CONTRACTOR',
  ADMIN = 'ADMIN', // author, admins in org
  READ_ONLY = 'READ_ONLY', // people in org
}
export interface ICorrectiveActionPageViewModelProps {
  authorName: string
  contractorName: string
  correctiveAction: ICorrectiveAction | undefined
  correctiveActionLoading: boolean
  correctiveActionLoadError: string | undefined
  reloadCorrectiveAction: () => void
  loadCorrectiveActionComments: () => void
  setCorrectiveAction: (correctiveAction: ICorrectiveAction) => void
  user: IUser | undefined
  viewMode: ECorrectiveActionViewMode
  correctiveActionClosing: boolean
  isContractor: boolean
  isAdmin: boolean
  comments: ICorrectiveActionComment[]
  isOpen: boolean
  isClosed: boolean
  isUnderReview: boolean
  commentsLoading: boolean
  commentsError: string | undefined
  // Shea values
  isShea: boolean
  contractor: IContractor | undefined
  taskValue: string | undefined
  locationValue: string | undefined
  contactName: string | undefined
  contactEmail: string | undefined
  contactPhone: string | undefined
  contactRole: string | undefined
  lotName: string | undefined
}

const CorrectiveActionPageContext =
  React.createContext<ICorrectiveActionPageViewModelProps | null>(null)

export function useCorrectiveActionPageViewModel(): ICorrectiveActionPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(CorrectiveActionPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const CorrectiveActionPageViewModel: React.FC<Props> = ({
  children,
}) => {
  const { id } = useParams()
  const [correctiveAction, setCorrectiveAction] = useState<ICorrectiveAction>(
    {},
  )
  const [correctiveActionLoadError, setCorrectiveActionLoadError] = useState<
    string | undefined
  >(undefined)
  const [correctiveActionLoading, setCorrectiveActionLoading] = useState(false)
  const [correctiveActionClosing, setCorrectiveActionClosing] = useState(false)

  const [contractor, setContractor] = useState<IContractor | undefined>(
    undefined,
  )
  const [comments, setComments] = useState<ICorrectiveActionComment[]>([])
  const [commentsLoading, setCommentsLoading] = useState<boolean>(false)
  const [commentsError, setCommentsError] = useState<string | undefined>(
    undefined,
  )
  const [viewMode, setViewMode] = useState(ECorrectiveActionViewMode.ADMIN)
  const { isContractor, org } = useOrganizationProvider()
  const user = getCurrentUser()
  const person = getCurrentPerson()
  const [isShea, setIsShea] = useState<boolean>(false)

  const [taskValue, setTaskValue] = useState<string | undefined>(undefined)
  const [locationValue, setLocationValue] = useState<string | undefined>(
    undefined,
  )
  const [contactName, setContactName] = useState<string | undefined>(undefined)
  const [contactEmail, setContactEmail] = useState<string | undefined>(
    undefined,
  )
  const [contactPhone, setContactPhone] = useState<string | undefined>(
    undefined,
  )
  const [contactRole, setContactRole] = useState<string | undefined>(undefined)
  const [lotName, setLotName] = useState<string | undefined>(undefined)

  // TODO: When we get to roles/permissions. Revisit this logic
  const getViewMode = () => {
    if (isContractor) {
      setViewMode(ECorrectiveActionViewMode.CONTRACTOR)
      return
    }
    setViewMode(ECorrectiveActionViewMode.ADMIN)
    // No Read Only mode for now but this will be added
  }

  const loadCorrectiveAction = async () => {
    const res = await getCorrectiveActionById(id!)

    if (res.isLeft()) {
      setCorrectiveActionLoading(false)
      setCorrectiveActionLoadError(
        res.left?.message ?? 'Error loading corrective action',
      )
      return
    }

    getViewMode()
    const ca = res.right as ICorrectiveAction
    setCorrectiveAction(ca)
    const isTheShea = ca.orgId == SHEA_HOMES_ID
    setIsShea(isTheShea)
    // contractorId is a required field
    if (isTheShea && ca.contractorId) {
      // get select contractor from CA
      // only do this for shea
      const submission = await anonGetOneSubmission(
        ca.orgId!,
        ca.formId!,
        ca.submissionId!,
      )

      // get form from the CA
      const form = await anonGetOneForm(ca.formId!, ca.orgId!)
      // the submission where CA came from.

      // const submission = await getSubmissionById(ca.submissionId ?? '')
      const components: IFormComponent[] = form.right?.components
        ? form.right.components
        : []

      try {
        // grab tasks/locations Values from select to get the correct Label

        const tasks = findComponent(components, 'task', false, false, 0)
        const task = tasks!.data.values.find((value: any) => {
          return submission.right?.data!.correctiveAction.task == value.value
        })
        setTaskValue(task?.label ?? '')

        const locations = findComponent(components, 'location1', false, false, 0)
        const location = locations!.data.values.find((l: any) => {
          return l.value == submission.right?.data!.location1
        })
        setLocationValue(location?.label ?? '')

        if(submission.right?.data!.location.name){
          setLotName(submission.right?.data!.location.name)
        }


        // see if selected contact
        if (ca.contactPersonId) {
          const contactRes = await getContactById(ca.contactPersonId)
          if (contactRes.right) {
            const { fullName, email, phone, title } = contactRes.right
            setContactName(fullName)
            setContactEmail(email)
            setContactPhone(phone)
            setCorrectiveActionLoading(false)
            setContactRole(title ?? '')
            return
          }
        }

        // then use contractor point of contact if no contact
        if (ca.contractorId) {
          const contractorRes = await getContractorById(ca.contractorId)
          if (contractorRes.right) {
            const {
              pointOfContactName,
              pointOfContactEmail,
              pointOfContactPhone,
            } = contractorRes.right
            setContactName(pointOfContactName)
            setContactEmail(pointOfContactEmail)
            setContactPhone(pointOfContactPhone)
            setContactRole('Contractor')
            setCorrectiveActionLoading(false)
            return
          } else {
            setContactRole(undefined)
            setContactName(person?.fullName ?? '')
            setContactEmail(person?.email ?? '')
            setContactPhone(person?.phone ?? '')
            setCorrectiveActionLoading(false)
          }
        }
        setCorrectiveActionLoading(false)
      } catch (err) {
        console.log({err})
        setTaskValue('-')
        setLocationValue('-')
        setCorrectiveActionLoading(false)
      }
    }
  }
  const loadCorrectiveActionComments = async () => {
    setCommentsError(undefined)
    setCommentsLoading(true)
    const res = await getCorrectiveActionsComment(id!)
    setCommentsLoading(false)

    if (res.isLeft()) {
      setCommentsError(res.left?.message ?? 'Error loading comments.')
      return
    }
    // TODO: check with backend, they should return in correct order
    const newComments = res.right?.data ?? []
    setComments(newComments.reverse())
  }

  useEffect(() => {
    loadCorrectiveAction()
    loadCorrectiveActionComments()
  }, [])

  return (
    <CorrectiveActionPageContext.Provider
      value={{
        correctiveAction,
        correctiveActionLoadError,
        correctiveActionLoading,
        commentsLoading,
        commentsError,
        reloadCorrectiveAction: loadCorrectiveAction,
        setCorrectiveAction,
        user,
        viewMode,
        authorName: correctiveAction?.authorName ?? '',
        contractorName: correctiveAction?.contractorName ?? '',
        correctiveActionClosing,
        loadCorrectiveActionComments: loadCorrectiveActionComments,
        comments: comments,
        isContractor,
        isAdmin: viewMode === ECorrectiveActionViewMode.ADMIN,
        isOpen: correctiveAction?.status === ECorrectiveActionStatus.OPEN,
        isClosed: correctiveAction?.status === ECorrectiveActionStatus.CLOSED,
        isUnderReview:
          correctiveAction?.status === ECorrectiveActionStatus.UNDER_REVIEW,
        isShea,
        contractor,
        taskValue,
        locationValue,
        contactName,
        contactEmail,
        contactPhone,
        contactRole,
        lotName
      }}
    >
      {children}
    </CorrectiveActionPageContext.Provider>
  )
}
