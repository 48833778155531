import { useEffect, useState } from 'react'
import {
  anonGetSubmission,
  getCurrentUser,
  getSubmittedFormById,
} from '../../domain/domain'
import { ISubmission } from '../../domain/interfaces/ISubmission'
import { getInitialToken } from '../../data/datasources/RemoteSource'
import { getFromLocalStorage } from '../../data/data'
import { useLocation } from 'react-router-dom'

// Not sure if this is needed
export const defaultSub = {
  orgName: 'mock org name',
  ownerName: '',
  ownerEmail: '',
  ownerPhone: '',
  targetAccountId: '',
  data: {
    userAutofillSelf: getCurrentUser()?.id,
  },
  orgId: '',

  id: 'null-submission-id',
  submittedBy: 'null-submitter name',
  createdAt: 'null date',
  updatedAt: 'null date',
}

export interface ISubmissionLoader {
  submission: ISubmission | undefined
  loadSubmissionError: string | undefined

  reloadSubmission: (subId?: string) => void
  submissionLoading: boolean
  submissionFormatted: boolean
  setSubmission: React.Dispatch<React.SetStateAction<ISubmission | undefined>>
  resetSubmission: () => void
}

//
const useSubmissionLoader = (
  subId?: string,
  orgId?: string,
  formId?: string,
  isAnon = false,
): ISubmissionLoader => {
  // const  res = await getSubmittedFormById(subId!)

  const location = useLocation()

  const getOrgIdFromUrl = () => {
    let orgId = ''
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('orgId')
    if (id) {
      orgId = id
    }
    return orgId
  }

  const [submission, setSubmission] = useState<ISubmission | undefined>(
    undefined,
  )
  const [submissionFormatted, setSubmissionFormatted] = useState<boolean>(false)
  const [submissionLoading, setSubmissionLoading] = useState<boolean>(true)
  const [loadSubmissionError, setLoadSubmissionError] = useState<
    string | undefined
  >(undefined)

  const loadSubmission = async (subId?: string) => {
    setLoadSubmissionError(undefined)
    setSubmissionLoading(true)
    if (!subId) {
      setSubmissionLoading(false)
      setSubmission(defaultSub)
      setSubmissionFormatted(true)
      return
    }
    // edit/show have submission
    if (submission && !subId) {
      setSubmissionLoading(false)
      setSubmissionFormatted(true)
      return
    }

    let res
    if (isAnon) {
      res = await anonGetSubmission(orgId!, formId!, subId!)
    } else {
      res = await getSubmittedFormById(subId!)
    }
    if (res.isLeft()) {
      setLoadSubmissionError(res.left?.message ?? 'Error')
      setSubmissionLoading(false)
      setSubmissionFormatted(true)
      return
    }
    let copySub = JSON.parse(JSON.stringify(res.right))
    if (copySub && copySub.data) {
      // DO WE NEED TO DO THIS?
      // if (copySub.data.level1) {
      //   const levelRes = await getLevelById(copySub.data.level1)
      //   if (levelRes.isRight()) {
      //     copySub.data.level1 = levelRes.right?.name ?? ''
      //   } else {
      //     copySub.data.level1 = res.right?.level1Name ?? ''
      //   }
      // }
      // ISSUE HERE WITH NOT PUTTING IN THE RIGHT LEVEL1 NAME/ID When appropriate(edit)
      // ALSO causing name to be listed twice.




      // TODO: is this the proper way TO GET ORGID AND TOKEN ?
      const token = getInitialToken()
      const org = getFromLocalStorage('org')
      const orgId = org ? JSON.parse(org).id : getOrgIdFromUrl()

      const sub = JSON.stringify(copySub)
      const regex = /\?token=([^&]*)&orgId=([^&]*)&/g
      const updatedSub = sub.replace(regex, (match, p1, p2) => `?token=${token}&orgId=${orgId}&`)

      copySub = JSON.parse(updatedSub)


      // temp fix for double name in edit list
      localStorage.setItem('initialSubmission', JSON.stringify(copySub))

      // set level1 name and project name correctly
      // but causes filtered api calls to use name instead of id
      copySub.data.level1 = res.right?.level1Name ?? ''
      copySub.data.project = copySub.projectName ?? copySub.data.project ?? ''
      setSubmission(copySub as ISubmission)
      setSubmissionLoading(false)
      setSubmissionFormatted(true)
    } else {
      setLoadSubmissionError('Error formatting submission')
      setSubmissionLoading(false)
      setSubmissionFormatted(true)
    }
  }

  useEffect(() => {
    if (subId && !submission) {
      loadSubmission(subId)
      return
    }
    setSubmissionLoading(false)
    setSubmission({ ...defaultSub })
    setSubmissionFormatted(true)
    return () => {
      localStorage.removeItem('submission')
      localStorage.removeItem('initialSubmission')
    }
  }, [])

  const resetSubmission = () => {
    // setting loading triggers a unmount and mount which
    // helps reset back to original submission
    setSubmissionLoading(true)
    const initSub = localStorage.getItem('initialSubmission')
    if (initSub) {
      const parsedInitSub = JSON.parse(initSub)
      setSubmission(parsedInitSub)
    }
    setTimeout(() => {
      setSubmissionLoading(false)
    }, 1000)
  }
  return {
    submission,
    submissionLoading,
    setSubmission,
    loadSubmissionError,
    submissionFormatted,
    reloadSubmission: loadSubmission,
    resetSubmission,
  }
}

export default useSubmissionLoader
