import { Box, Toolbar, AppBar } from '@mui/material'
import { AppColors } from '../../../Theme'

import React, { useState } from 'react'
import { RomeLogo } from '../../../../core/core'
import { ProfileTile } from '../../tiles/ProfileTile'
import {
  getAuthToken,
  getCurrentOrgId,
  getCurrentPerson,
  getCurrentUser,
} from '../../../../domain/domain'
import { EdifyDrawer, drawerWidth } from '../drawer/EdifyDrawer'
import { SettingsPopoverViewModel } from '../../dialogs/settings-popover-dialog/SettingsPopoverViewModel'
import { SettingsPopoverDialog } from '../../dialogs/settings-popover-dialog/SettingsPopoverDialog'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PERSON_PROFILE } from '../../../pages/person_profile/PersonProfilePage'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import { useGlobalProvider } from '../../../../providers/GlobalProvider'
import { formatSnakeCase } from '../../../../core/utils/text-formaters/TextFormatters'
import { ROUTE_USERS } from '../../../pages/users/UsersPage'

export enum AppBarTheme {
  LIGHT = 'light',
  DARK = 'dark',
}
interface Props {
  theme?: AppBarTheme
  barItems?: React.ReactElement | React.ReactElement[]
  children: React.ReactElement | React.ReactElement[]
  showDrawer?: boolean
  isFromOrg?: boolean
}

// ORG SPECIFIC PAGE NAVBAR
export const EdifyAppBarSearch: React.FC<Props> = ({
  children,
  barItems,
  theme = AppBarTheme.LIGHT,
}) => {
  const orgVm = useOrganizationProvider()
  const { isGlobalUserView, isGlobalUser } = useGlobalProvider()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleSettingsClose = () => {
    setIsOpen(false)
  }
  // Disabled for now since we no longer have dropdown
  const handleSettingsClick = () => {
    if (orgVm.isContractor) {
      navigate(`${ROUTE_PERSON_PROFILE}/org`)
      return
    }
    const userId = getCurrentUser()?.id
    navigate(`${ROUTE_USERS}/${userId}`)
  }

  // Name is global, role is org/user specific
  const { globalRole } = getCurrentPerson() || {}
  const token = getAuthToken()
  const orgId = getCurrentOrgId() ?? ''

  const currentUser = getCurrentUser()

  const getRoleName = () => {
    if (isGlobalUserView && globalRole?.name) {
      return formatSnakeCase(globalRole?.name)
    }
    return currentUser && currentUser.roles[0]
      ? formatSnakeCase(currentUser.roles[0].name)
      : 'Contractor'
  }
  const getImageUrl = () => {
    if (orgVm.profileTile.imageURL && orgVm.profileTile.imageURL != '') {
      return orgVm.profileTile.imageURL + `&token=${token}`
    }
  }
  const role = getRoleName()

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        elevation={0}
        position='fixed'
        sx={{
          backgroundColor: AppColors.gray950,
          paddingX: 18,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            height: '70px',
            display: 'flex',
            alignContent: 'center',
            gap: '0px',
          }}
        >
          <RomeLogo
            logoUrl={orgVm.org?.logoUrl}
            socTokenAndOrgId={`&token=${token}&orgId=${orgId}`}
            orgName={orgVm.org?.name ?? undefined}
            isDarkTheme={theme === AppBarTheme.DARK}
          />

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {barItems}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ProfileTile
              imageUrl={getImageUrl()}
              name={orgVm.profileTile.name ?? ''}
              role={role}
              isGlobalUserView={isGlobalUserView && isGlobalUser}
              isDarkMode={theme === AppBarTheme.DARK}
              onClick={handleSettingsClick}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <SettingsPopoverViewModel>
        <SettingsPopoverDialog
          open={isOpen}
          handleClose={handleSettingsClose}
        />
      </SettingsPopoverViewModel>
      <EdifyDrawer />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: orgVm?.showDrawer ? drawerWidth : 0,
        }}
        // sx={{ flexGrow: 1, p: 3, marginLeft: 0 }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}
