import React, { useState } from 'react'
import { GridBadge, GridItem } from '../gridItems'
import { GridColDef } from '@mui/x-data-grid'

import {
  ECorrectiveActionStatus,
  ICorrectiveAction,
} from '../../../../domain/interfaces/ICorrectiveAction'
import { MenuItem } from '@mui/material'
import { EdifySelect } from '../../form'
import { CorrectiveActionsPageViewModelProps } from '../../../pages/corrective-actions/CorrectiveActionsViewModel'
import { getTimeAgo } from '../../../../core/core'
import { EdifyButton } from '../../buttons'
import { AppColors } from '../../../Theme'
import EdifyDatePicker from '../../form/EdifyDatePicker'
import ImageDialog from '../../dialogs/ImageDialog'

function shortenString(str = '') {
  if (!str) return ''
  if (str.length > 80) {
    return str.substring(0, 80) + '...'
  }
  return str
}

// Hardcoded columns for Corrective Actions
export const correctiveActionColumns: (
  l0Name: string,
  l1Name: string,
) => GridColDef[] = (l0Name, l1Name) => {
  return [
    {
      field: 'correctiveItemId',
      width: 200,
      cellClassName: 'white-space-wrap',
      renderHeader: () => <GridItem text='Corrective Action ID' />,
      renderCell: ({ row }) => (
        <GridItem
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          text={row.id}
        />
      ),
    },
    {
      field: 'level',
      width: 200,
      cellClassName: 'white-space-wrap',
      renderHeader: () => <GridItem text={l1Name} />,
      renderCell: ({ row }) => (
        <GridItem
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          text={row.levelName}
        />
      ),
    },

    {
      field: 'form',
      width: 200,
      cellClassName: 'white-space-wrap',
      renderHeader: () => <GridItem text='Form' />,
      renderCell: ({ row }) => (
        <GridItem
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          text={row.formTitle}
        />
      ),
    },
    {
      field: 'project',
      width: 200,
      cellClassName: 'white-space-wrap',
      renderHeader: () => <GridItem text={l0Name} />,
      renderCell: ({ row }) => (
        <GridItem
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          text={row.projectName}
        />
      ),
    },
    // {
    //   field: 'location',
    //   width: 200,
    //   cellClassName: 'white-space-wrap',
    //   renderHeader: () => <GridItem text='Location' />,
    //   renderCell: ({ row }) => (
    //     <GridItem
    //       onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
    //       text={row.location}
    //     />
    //   ),
    // },
    {
      field: 'task',
      width: 200,
      cellClassName: 'white-space-wrap',
      renderHeader: () => <GridItem text='Task' />,
      renderCell: ({ row }) => (
        <GridItem
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          text={row.task}
        />
      ),
    },

    {
      field: 'notes',
      cellClassName: 'white-space-wrap',
      renderHeader: () => <GridItem text='Description' />,
      width: 250,
      renderCell: ({ row }) => {
        return (
          <GridItem
            onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
            text={shortenString(row.notes)}
            sx={{ textOverflow: 'ellipsis' }}
          />
        )
      },
    },
    {
      field: 'constractionManager',
      renderHeader: () => <GridItem text='Trade' />,
      width: 200,
      renderCell: ({ row }) => (
        <GridItem
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          text={row.contractorName}
        />
      ),
    },
    {
      field: 'createdBy',
      renderHeader: () => <GridItem text='Created' />,
      width: 200,
      renderCell: ({ row }) => (
        <GridItem
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          text={row.authorName}
        />
      ),
    },
    {
      field: 'priority',
      renderHeader: () => <GridItem text='Priority' />,
      width: 200,
      renderCell: ({ row }) => (
        <GridItem
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          text={row.priority}
          alert={row.priority === 'Critical'}
        />
      ),
    },
    // {
    //   field: 'trade',
    //   editable: true,
    //   renderHeader: () => <GridItem text='Trade' />,
    //   width: 200,

    //   renderCell: ({ row }) => (
    //     <GridItem
    //       onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
    //       text={row.trade}
    //     />
    //   ),
    // },
    {
      field: 'status',
      renderHeader: () => <GridItem text='Status' />,
      width: 250,
      renderCell: ({ row }) => (
        <StatusDropDown
          canEdit={row.canEdit}
          id={row.id}
          text={row.status}
          vm={row.vm}
        />
      ),
    },
    {
      field: 'dateInspected',
      width: 200,
      renderHeader: () => <GridItem text='Date Inspected' />,
      renderCell: ({ row }) => (
        <GridBadge
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          type='grey'
          text={getTimeAgo(row.createdAt)}
        />
      ),
    },
    {
      field: 'dueDate',
      width: 200,
      renderHeader: () => <GridItem text='Due Date' />,
      renderCell: ({ row }) => {
        (e: any) => e.stopPropagation()
        return (
          <DueDate
            canEdit={row.canEdit}
            date={row.dueDate}
            id={row.id}
            status={row.status}
            vm={row.vm}
          />
        )
      },
    },
    {
      field: 'daysLate',
      width: 200,
      renderHeader: () => <GridItem text='Days Late' />,
      renderCell: ({ row }) => (
        <GridBadge
          onClick={() => row.navigate(`/correctiveActions/${row.id}`)}
          type='grey'
          text={row.daysLate}
        />
      ),
    },
    {
      field: 'completedDate',
      width: 200,
      renderHeader: () => <GridItem text='Completed Date' />,
      renderCell: ({ row }) => {
        (e: any) => e.stopPropagation()
        return (
          <CompleteDate
            canEdit={row.canEdit}
            date={row.closedAt}
            id={row.id}
            status={row.status}
            vm={row.vm}
          />
        )
      },
    },
    {
      field: 'photos',
      width: 135,
      // flex:1,

      renderHeader: () => <GridItem text='Photos' />,
      renderCell: ({ row }) => {
        const [open, setOpen] = React.useState(false)

        if (row.images?.length == 0) {
          return (
            <EdifyButton
              disabled
              noBackground
              buttonStyle={{ border:  'grey' }}
              title={'No Photos'}
              textStyle={{ color: 'grey' }}
            />
          )
        }
        return (
          <>
            <EdifyButton
              onClick={(e) => {
                setOpen(true)
                e.stopPropagation()
              }}
              // disabled={row.images?.length == 0}
              noBackground
              buttonStyle={{ border: `1px solid ${AppColors.primary700}` }}
              title={`Photos: ${row.images?.length ?? 'NA'}` }
              textStyle={row.images?.length == 0 ? { color: 'grey' } : {}}
            />
            <ImageDialog
              url={row.images[0]}
              open={open}
              onClose={() => setOpen(false)}
            />
          </>
        )
      },
    },
    // {
    //   field: 'closedAt',
    //   width: 130,
    //   renderHeader: () => <GridItem text='Closed Date' />,
    //   renderCell: ({ row }) => (
    //     <GridBadge
    //       type='grey'
    //       text={row.closedAt ? getTimeAgo(row.closedAt) : 'Open'}
    //     />
    //   ),
    // },
  ]
}

function camelToReadable(camelCase:string) {
  return camelCase
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
    .trim()
}

// Maps corrective actions static rows
export const normalizeCorrectiveActionRows = (
  correctiveActions: ICorrectiveAction[],
  navigate: any,
  canEdit: boolean,
  vm: CorrectiveActionsPageViewModelProps,
): any[] => {
  if (!correctiveActions) return []
  return correctiveActions.map((sub: ICorrectiveAction) => {
    return {
      id: sub.id,
      title: { text: sub.formTitle, date: sub.createdAt },
      orgId: sub.orgId,
      submissionId: sub.submissionId,
      status: sub.status,
      authorId: sub.authorId,
      contractorId: sub.contractorId,
      closedBy: sub.closedBy,
      closedAt: sub.closedAt,
      createdAt: sub.createdAt,
      updatedAt: sub.updatedAt,
      reminder: sub.reminder,
      priority: sub.priority,
      notes: sub.notes,
      dueDate: sub.dueDate,
      question: sub.question,
      answer: sub.answer,
      images: sub.images,
      levelId: sub.levelId,
      levelName: sub.levelName,
      projectId: sub.projectId,
      daysLate: sub.daysLate,
      trade: sub.trade,
      location: sub.location,
      task: camelToReadable(sub.task ?? ''),
      type: sub.type,
      authorName: sub.authorName,
      contractorName: sub.contractorName,
      projectName: sub.projectName,
      formTitle: sub.formTitle,
      formId: sub.formId,
      navigate: navigate,
      canEdit: canEdit,
      vm: vm,
    }
  })
}

interface StatusDropDownProps {
  name?: string
  text: string
  id: string
  canEdit: boolean
  vm: CorrectiveActionsPageViewModelProps
}

/// component
const StatusDropDown: React.FC<StatusDropDownProps> = ({
  text = 'resolved',
  id,
  canEdit,
  vm,
}) => {
  const caStatusOptions = [
    { value: 'Closed', name: 'Closed' },
    { value: 'Open', name: 'In Progress' },
    { value: 'Under Review', name: 'Under Review' },
  ]

  const [value, setValue] = useState(text)
  //  "status":string //'Submitted', 'Sent', 'In Progress', 'Closed'
  const handleChange = async (e: any) => {
    const { value } = e.target
    vm.correctiveActionUpdate(id, { status: value })
    // vm.fetchCorrectiveActions(-1)
    // setValue(e.target.value)
    // if (e.target.value == ECorrectiveActionStatus.CLOSED) {
    //   await closeCorrectiveAction(id, { notes: 'closed from grid' })
    //   setValue(e.target.value)
    //   vm.fetchCorrectiveActions(-1)
    // }
    // if (e.target.value == ECorrectiveActionStatus.UNDER_REVIEW) {
    //   await addCommentOnCorrectiveAction(id, {
    //     notes: 'Under review from grid',
    //     images: []
    //   })
    //   setValue(e.target.value)
    //   vm.fetchCorrectiveActions(-1)
    // }
    // if (e.target.value == ECorrectiveActionStatus.OPEN) {
    //   await sendBackCorrectiveAction(id, {
    //     notes: 'sent back review from grid',
    //     images: []
    //   })
    //   setValue(e.target.value)
    //   vm.fetchCorrectiveActions(-1)
    // }
  }
  return (
    <EdifySelect
      disabled={!canEdit || value == ECorrectiveActionStatus.CLOSED}
      width={180}
      value={value}
      dropDownItems={caStatusOptions}
      onChange={handleChange}
      renderMenuItem={(s) => (
        <MenuItem key={s.value} value={s.value}>
          {s.name}
        </MenuItem>
      )}
    ></EdifySelect>
  )
}

interface DateProps {
  date: string
  id: string
  canEdit: boolean
  status: string
  vm: CorrectiveActionsPageViewModelProps
}

/// component
const CompleteDate: React.FC<DateProps> = ({
  date,
  id,
  canEdit,
  status,
  vm,
}) => {
  const setDate = async (date: any) => {
    vm.correctiveActionUpdate(id, {
      completedDate: date.toDate(),
      status: 'Closed',
    })
    // vm.fetchCorrectiveActions(-1)
  }

  return (
    <EdifyDatePicker
      disabled={!canEdit || status == ECorrectiveActionStatus.CLOSED}
      date={date}
      setDate={setDate}
    />
  )
}

/// component
const DueDate: React.FC<DateProps> = ({ date, id, canEdit, status, vm }) => {
  const setDate = async (date: any) => {
    vm.correctiveActionUpdate(id, { dueDate: date.toDate() })
    // vm.fetchCorrectiveActions(-1)
  }
  return (
    <EdifyDatePicker
      disabled={!canEdit || status == ECorrectiveActionStatus.CLOSED}
      date={date}
      setDate={setDate}
    />
  )
}
