import React, { useEffect } from 'react'
import { getAuthToken } from '../../../../domain/domain'
import { use } from 'i18next'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import { useLocation } from 'react-router-dom'

interface IImageWithTokenProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  orgId?: string
  isPDF?: boolean
  setUpdatedSRC?: (src: string) => void
}

const ImageWithToken: React.FC<IImageWithTokenProps> = ({
  src,
  orgId,
  setUpdatedSRC,
  isPDF = false,
  ...rest
}) => {
  const token = getAuthToken()
  const org = useOrganizationProvider()

  const location = useLocation()

  if (!orgId) {
    if (org) {
      orgId = org.org?.id
    } else {
      const queryParams = new URLSearchParams(location.search)
      const id = queryParams.get('orgId')
      if (id) {
        orgId = id
      }
    }
  }

  let swappedSrc = src
  if (token) {
    swappedSrc += `&token=${token}`
  }
  if (orgId) {
    swappedSrc += `&orgId=${orgId}`
  }

  useEffect(() => {
    if (setUpdatedSRC) {
      setUpdatedSRC(swappedSrc)
    }
  }, [])

  // need to add something like this so it doesn't download the pdf
  // this needs to happen on the server side
  //+ '&response-content-disposition=inline&response-content-type=application'
  
  if (isPDF) {
    return <iframe src={swappedSrc+'&inline=true'} width='100%' height='100%' title='PDF Viewer' />
  }
  return <img src={swappedSrc} {...rest} />
}

export default ImageWithToken
