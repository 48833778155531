import React from 'react'
import { ETypography } from '../../fonts/ETypography'
import { EBox } from '../../box/Ebox'
import { TrashBlueIcon } from '../../../../core/components/icons/TrashBlueIcon'
import { Box, SxProps } from '@mui/material'
import ImageDialog from '../../dialogs/ImageDialog'

import PDFViewerModal from '../../../pages/document-library/PDFViewerModal'
import ImageWithToken from './ImageWithToken'

interface EdifyAttachmentViewerProps {
  attachment: string
  name?: string
  description?: string
  type?: string
  onDelete?: () => void
  sx?: SxProps
}

const EdifyAttachmentViewer: React.FC<EdifyAttachmentViewerProps> = ({
  attachment: image,
  name = 'Image',
  description = '',
  type = 'image',
  onDelete,
  sx,
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  if (image.includes('.pdf')) {
    type = 'file'
    name = 'PDF'
  }

  const imgComponent = (
    <ImageWithToken
      src={image}
      alt={name}
      style={{
        width: '48px',
        height: '48px',
        marginBottom: '16px',
        marginRight: '16px',
        cursor: 'pointer',
      }}
    />
  )
  const fileComponent = (
    <img
      src='https://res-academy.cache.wpscdn.com/images/seo_posts/20230705/e2586bdc1cda16d92fd0632491a71092.png'
      alt={name}
      style={{
        width: '48px',
        height: '48px',
        marginBottom: '16px',
        marginRight: '16px',
        cursor: 'pointer',
      }}
    />
  )

  return (
    <>
      <EBox
        p='16'
        border='primary'
        onClick={() => setOpen(true)}
        sx={{
          display: 'flex',
          jc: 'flex-start',
          fd: 'row',
          minWidth: '280px',
          borderRadius: '10px',
          width: 'calc(50% - 12px)',
          ...sx,
        }}
      >
        {type === 'image' ? imgComponent : fileComponent}

        <EBox style={{ flex: 1 }} sx={{ overflow: 'hidden' }}>
          <ETypography font='SM' color='gray700'>
            {name}
          </ETypography>
          <ETypography font='SR' color='gray50'>
            {description}
          </ETypography>
        </EBox>
        {onDelete && (
          <Box
            sx={{
              marginLeft: '12px',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={(e) => {
              e.stopPropagation() // Stop click event propagation
              onDelete() // Trigger the onDelete action
            }}
          >
            <TrashBlueIcon />{' '}
          </Box>
        )}
      </EBox>
      {type === 'image' && (
        <ImageDialog
          url={image}
          open={open}
          onClose={handleClose}
          type={type}
        />
      )}
      {type === 'file' && (
        <PDFViewerModal
          open={open}
          onClose={handleClose}
          pdfId='-1'
          pdfUrl={image}
          skipLoad={true}
        />
      )}
    </>
  )
}

export default EdifyAttachmentViewer
