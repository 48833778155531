import { ETrade } from './IContractor'
import { IPerson } from './IPerson'
// TODO: GO OVER THIS  FILE ONCE THE DUST SETTLES FROM THIS SPRINT

export enum ECorrectiveActionStatus {
  OPEN = 'Open',
  CLOSED = 'Closed',
  UNDER_REVIEW = 'Under Review',
}

export enum EReminder {
  UNTIL_RESOLVED = 'Until Resolved',
  ONE_TIME_ONLY = 'One Time Only',
}

export interface ICorrectiveActionComment {
  id: string
  createdAt: string
  updatedAt: string
  caId: string
  senderId: string
  notes: string
  senderName: string
  images: ICorrectiveActionImage[]
}
export interface ICorrectiveActionCommentList {
  data: ICorrectiveActionComment[]
  total_count: number
  message: string
}

export interface ICorrectiveAction {
  id?: string
  orgId?: string
  authorName?: string
  projectName?: string
  submissionId?: string
  daysLate?: string
  status?: ECorrectiveActionStatus
  authorId?: string
  contractorId?: string
  contactPersonName?: string
  contactPerson?: any
  closedBy?: string
  closedAt?: string
  comment?: string
  closingComment?: string
  createdAt?: string
  formId?: string
  formTitle?: string
  updatedAt?: string
  reminder?: EReminder
  assignedPersons?: IPerson[]
  responsibleParties?: string
  responsiblePartiesFormString?: string
  contactPersonId?: string
  contractorName?: string
  notes?: string
  comments?: ICorrectiveActionComment[]
  question?: string
  answer?: string
  dueDate?: string
  images?: ICorrectiveActionImage[]
  levelId?: string
  levelName?: string
  projectId?: string
  trade?: ETrade
  location?: string
  task?: string
  type?: string
  priority?: string
  responsiblePartyName?: string
  closedByName?: string
  sublocationLevel1Name?: string
  sublocationLevel2Name?: string
  sublocationLevel3Name?: string
  sublocationLevel4Name?: string
  sublocationLevel5Name?: string
  locationId?: string
}
export interface ICorrectiveActionList {
  code?: number
  data?: ICorrectiveAction[]
  message: string
  total_count: number
}

export interface ICorrectiveActionSubmitData {
  images?: string[]
  notes: string
}

// {
//   "dueDate":string,
//   "status":string //'Submitted', 'Sent', 'In Progress', 'Closed'
// }

export interface ICorrectiveActionUpdateData {
  dueDate?: string
  completedDate?: string
  closedAt?: string
  status?: string
}

// so far only id and path are coming back from db but design wants title/description so I am adding them now and will handle null values on front end
export interface ICorrectiveActionImage {
  id: string
  path: string
  title?: string
  description?: string
}
